














































import { Vue, Component } from 'vue-property-decorator';

import { H3Button, H3Input } from '@h3/thinking-ui';

import CommonInput from './common-input.vue';

import { LoginService } from '@WorkPlatform/services';

import { RsaEncrypt } from '@WorkPlatform/common/rsg';
import { namespace } from 'vuex-class';
const Global = namespace('Global');

@Component({
  name: 'ChangePassword',
  components: {
    H3Button,
    H3Input,
    CommonInput,
  },
})
export default class ChangePassword extends Vue {
  @Global.Action('getAllPerms') getAllPerms!: any;

  // 账号
  username: string = '';

  password: string = '';

  submitting: boolean = false;

  usernameShow: boolean = false;

  passwordShow: boolean = false;

  height: number = 0;

  loginError: string = '';

  // 加密公钥
  rsaKey: any = {};

  // 是否可以登录
  get submitPassword () {
    return !(this.username && this.password);
  }

  /**
   * 如果是不是admin，则需要获取用户信息之后再跳转到运行时，运行时依赖这些用户信息
   */
  isNotAdminAndRuntimeUser () {
    const redirectUrl: string = this.$route?.query?.redirect_url as string || '';

    if (this.username !== 'admin' && redirectUrl.includes('app_runtime')) {
      return true;
    }

    return false;
  }

  /**
   * 登录
   */
  async submit () {
    this.submitting = true;

    // 获取publicKey
    LoginService.getKey().then((resKey: any) => {
      if (!resKey.success) return;

      var dataKey: any = resKey.data;

      const flag: boolean =
      typeof dataKey === 'object' && dataKey.index && dataKey.key;

      if (!flag) return;

      var { index, key } = dataKey;

      const password: any = RsaEncrypt(this.password, key);

      // rsa加密结束
      const params: any = {
        username: this.username,
        password,
        client_id: 'api',
        index,
      };
      // 登录
      return LoginService.login(params);
    }).then(async (res: any) => {
      if (!res.success || !res.data) return;
      this.setInfoToLocal(res.data);

      if (res.data.specification) {
        localStorage.setItem('sessToken', (res.data as any).access_token);
        this.submitting = false;
        this.$modal.confirm({
          title: '',
          content: '密码不符合规则，请前往修改',
          onConfirm: async () => {
            this.$router.push({
              name: 'm-work-platform-change-password',
            }).catch(() => {});
          },
          onCancel () {
          },
        });

        return new Promise(() => {});
      }

      this.getToken(res.data);

      // 获取权限
      return this.getAllPerms();
    }).then(() => {
      const redirectUrl: string = this.$route.query.redirect_url as string;
      if (redirectUrl) {
        window.location.href = redirectUrl.replace('hashsymbol', '#');
      } else {
        this.$router.replace({
          name: 'm-work-platform-home',
        }).catch(() => {});
      }
    }).catch((e: Error) => {
      const getErrorStatus = (error: any) =>
        error.httpStatus || (error.response && error.response.status);
      const status: any = getErrorStatus(e);

      if (status === 403) {
        this.loginError = '账号或密码有误';
      }
    }).finally(() => {
      this.submitting = false;
      localStorage.removeItem('sessToken');
    });
  }

  /**
   * 获取token
   */
  async getToken (data: any) {
    const token = (data as any).access_token;
    localStorage.setItem('token', token);
    // 登录后，设置cookie
    const expires = new Date(data.expiration).toUTCString();
    document.cookie = `access_token=${token}; expires=${expires};path=/;`;
  }

  setInfoToLocal (data) {
    const userInfo: any = {
      name: data.userinfo ? data.userinfo.name : '-',
      username: data.userinfo ? data.userinfo.username : '-',
      mobile: data.userinfo.mobile || '-',
      userId: data.user_id,
      baseUserId: data.userinfo.id,
      imgUrl: data.userinfo.imgUrl,
    };

    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  keyDownEnter (event) {
    const e = event || window.event;
    if (e && e.keyCode === 13) {
      if (!this.submitPassword && !this.submitting) {
        this.submit();
      }
    }
  }

  created () {
    document.addEventListener('keydown', this.keyDownEnter);
    this.height = document.body.clientHeight - 470;
  }

  destroyed () {
    document.removeEventListener('keydown', this.keyDownEnter);
  }
}
